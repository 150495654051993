<template>
	<div class="w-full">
		<Draggable class="w-full" tag="div" :list="sortedObjects" :group="{ name: 'g1' }" :item-key="itemKey" :move="checkMove">
			<template #item="{ element, index }">
				<div class="flex flex-col justify-start w-full">
					<div class="flex flex-row justify-start items-center w-full">
						<span
							v-if="level > 1"
							class="mx-1 flex flex-col items-center justify-center"
							:style="{
								width: (level > 1 ? 25 * (level - 1) : 25 * level) + 'px',
							}"
						>
							<FontAwesomeIcon :icon="faTurnDownRight" class="h-5 w-5 text-black"></FontAwesomeIcon>
						</span>
						<div
							class="flex flex-row justify-between h-8 rounded pl-2 pr-3 font-normal hover:font-bold my-1 cursor-pointer w-full"
							:class="{
								'bg-primary-800': selectedVariable !== currentVariableAddress(index),
								'bg-primary-600': selectedVariable === currentVariableAddress(index),
							}"
							@click="emits('click', (variableAddress ? variableAddress : '') + '[' + index + ']', !!element.group)"
						>
							<div class="flex items-center text-white w-full pointer-events-none justify-between">
								<div>
									<FontAwesomeIcon v-if="element.group" :icon="faFolder" class="mr-2"></FontAwesomeIcon>
									<span class="text-lg"> {{ element.name }}</span>
								</div>
								<div>
									<FontAwesomeIcon v-if="element.list" :icon="faSquareList" class="mr-2 text-grey-500 font-semibold align-text-bottom"></FontAwesomeIcon>
									<span class="text-sm text-grey-500 font-semibold align-bottom"> {{ element.type ? (element.type == 'datetime' ? 'DT' : element.type.charAt(0).toUpperCase()) : '' }}</span>
								</div>
							</div>
							<div v-if="element.group" class="flex items-center text-white">
								<FontAwesomeIcon
									:icon="faCaretUp"
									class="transition-all transform duration-75"
									:class="{
										'rotate-180': openGroups.includes(currentVariableAddress(index)),
									}"
								></FontAwesomeIcon>
							</div>
						</div>
					</div>
					<div v-if="element.group && openGroups.includes(currentVariableAddress(index))">
						<VariableSelector
							v-if="element.children.length > 0"
							:objects="element.children"
							:item-key="itemKey"
							:level="level + 1"
							:folder-name="element.name"
							:variable-address="(variableAddress ? variableAddress : '') + '[' + index + '].children'"
							:selected-variable="selectedVariable"
							:open-groups="openGroups"
							:can-create="canCreate"
							@click="(variableAddressClicked: string, isGroup: boolean) => emits('click', variableAddressClicked, isGroup)"
							@create="(variableAddressToCreateAt: string) => emits('create', variableAddressToCreateAt)"
						/>
						<div v-else class="flex flex-row justify-start items-center w-full">
							<span
								class="mx-1 flex flex-col items-center justify-center"
								:style="{
									width: (level > 1 ? 25 * (level - 1) : 25 * level) + 'px',
								}"
							>
								<FontAwesomeIcon :icon="faTurnDownRight" class="h-5 w-5 text-black"></FontAwesomeIcon>
							</span>
							<div class="flex flex-row justify-between items-center h-8 bg-gray-200 rounded px-3 font-normal my-1.5 cursor-pointer w-full">
								<span> No variables in this folder... yet</span>
							</div>
						</div>
						<div v-if="canCreate" class="flex flex-row justify-start items-center w-full my-0.5">
							<button
								type="button"
								class="flex justify-center items-center w-full rounded-lg border-2 border-dashed font-medium hover:font-bold border-gray-300 hover:border-gray-400 focus:outline-none h-8"
								:style="{
									'margin-left': (level > 1 ? 25 * (level - 1) : 25 * level) + 5 + 'px',
								}"
								@click="emits('create', (variableAddress ? variableAddress : '') + '[' + index + ']')"
							>
								<span class="block text-sm text-gray-900">
									<span class="mr-1">
										<FontAwesomeIcon :icon="faPlusLarge"></FontAwesomeIcon>
									</span>
									Create a new variable in "{{ folderName || element.name }}"
								</span>
							</button>
						</div>
					</div>
				</div>
			</template>
		</Draggable>
		<div v-if="level == 1 && canCreate" class="flex flex-row justify-start items-center w-full my-1.5">
			<button type="button" class="flex justify-center items-center w-full rounded-lg border-2 border-dashed font-medium hover:font-bold border-gray-300 hover:border-gray-400 focus:outline-none h-8" @click="emits('create', false)">
				<span class="block text-sm text-gray-900">
					<span class="mr-1">
						<FontAwesomeIcon :icon="faPlusLarge"></FontAwesomeIcon>
					</span>
					Create a new variable
				</span>
			</button>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { computed, defineEmits, defineProps, withDefaults } from 'vue';
	import { ActivityTemplateVariables } from '@/types/activityTemplate';
	import Draggable from 'vuedraggable';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faCaretUp, faFolder, faSquareList } from '@fortawesome/pro-regular-svg-icons';
	import { faPlusLarge, faTurnDownRight } from '@fortawesome/pro-light-svg-icons';

	interface Props {
		objects: ActivityTemplateVariables[];
		itemKey: string;
		level?: number;
		variableAddress?: string;
		selectedVariable: string;
		folderName?: string | boolean;
		openGroups: string[];
		canCreate?: boolean;
	}

	const props = withDefaults(defineProps<Props>(), {
		itemKey: 'id',
		level: 1,
		variableAddress: '',
		folderName: false,
		canCreate: true,
	});

	const sortedObjects = computed<ActivityTemplateVariables[]>(() => {
		return props.objects.sort((a: ActivityTemplateVariables, b: ActivityTemplateVariables) => (b?.group ?? false) - (a?.group ?? false));
	});

	const emits = defineEmits<{
		(e: 'click', address: string, group: boolean): void;
		(e: 'create', address: string | boolean): void;
	}>();

	const currentVariableAddress = (variableIndex: number) => {
		return (props.variableAddress ? props.variableAddress : '') + '[' + variableIndex + ']';
	};

	const checkMove = (event: unknown) => {
		return !event?.draggedContext?.element?.group;
	};
</script>
