<template>
	<div class="flex flex-col h-full w-full">
		<div class="h-12 bg-white border-b shadow-md flex flex-row items-center justify-between px-8 transition-all duration-500 transform">
			<div class="w-64"></div>
			<div class="font-bold text-lg"></div>
			<div class="w-64 text-right flex justify-end">
				<button
					class="flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-danger-500 hover:bg-gray-100 focus:outline-none"
					type="button"
					@click="() => createVariable(false)"
				>
					New variable
					<FontAwesomeIcon :icon="faPlus" class="h-5 w-5 text-white ml-2" aria-hidden="true" />
				</button>
			</div>
		</div>
		<div class="flex flex-row w-full h-full">
			<div class="flex flex-col h-full w-1/3">
				<div class="transition-all duration-500 transform h-full">
					<div class="bg-gray-50 py-3 px-4 transition-all duration-500 transform w-full h-full overflow-hidden">
						<VariableSelector :objects="builderStore.variables" item-key="id" :selected-variable="selectedVariableAddress" :open-groups="openGroups" @click="selectVariable" @create="createVariable"></VariableSelector>
					</div>
				</div>
			</div>
			<div class="flex flex-col h-full w-full border-l">
				<div v-if="selectedVariable" class="px-4 py-4 transition-all duration-500 transform h-full">
					<div class="bg-white rounded-lg pb-6 pt-4 sm:p-6 shadow-box-circle mx-auto transition-all duration-500 transform w-full h-full shadow-lg overflow-hidden">
						<div class="text-2xl font-bold text-center -mt-4">
							<h1>Variable editor</h1>
						</div>
						<VariableEditor v-if="selectedVariable" :key="selectedVariable.id" :variable="selectedVariable"></VariableEditor>
					</div>
				</div>
				<div v-else class="flex flex-row w-full h-full justify-center">
					<div class="transition-all duration-500 transform w-full h-full">
						<div class="rounded-lg px-7 pb-6 pt-4 sm:p-10 shadow-box-circle mx-auto transition-all duration-500 transform w-full flex flex-row justify-center items-center h-full">
							<div class="flex flex-col items-center">
								<h1 class="text-3xl font-bold">Welcome to the variable builder</h1>
								<p class="mt-4">Here you can alter the variables that will be attached to each activity of this template type.</p>
								<FontAwesomeIcon :icon="faWandMagicSparkles" aria-hidden="true" class="mt-8 h-24 w-24 flex-shrink-0 text-primary pointer-events-none text-4xl" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faPlus, faWandMagicSparkles } from '@fortawesome/pro-light-svg-icons';
	import { useBuilderStore } from '@modules/builder/store';
	import VariableSelector from '@modules/builder/components/VariableSelector.vue';
	import { ref, watch } from 'vue';
	import { get } from 'lodash';
	import VariableEditor from '@modules/builder/components/VariableEditor.vue';
	import { ActivityTemplateVariables } from '@/types/activityTemplate';

	const builderStore = useBuilderStore();

	const selectVariable = (variableAddress: string, isGroup: boolean) => {
		if (isGroup) {
			if (openGroups.value?.includes(variableAddress)) {
				openGroups.value = openGroups.value?.filter((address) => address !== variableAddress);
			} else {
				openGroups.value?.push(variableAddress);
			}
		}
		selectedVariableAddress.value = variableAddress;
	};

	const createVariable = async (variableAddressToCreateVariable: string | boolean) => {
		selectVariable(builderStore.addNewTemplateVariable(variableAddressToCreateVariable), false);
	};

	const selectedVariableAddress = ref<string>('');
	const selectedVariable = ref<ActivityTemplateVariables>();
	const openGroups = ref<string[]>([]);

	watch(selectedVariableAddress, async (newVariableAddress) => {
		selectedVariable.value = get(builderStore.variables, newVariableAddress) satisfies ActivityTemplateVariables;
	});
</script>

<style scoped></style>
