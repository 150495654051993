<template>
	<div class="h-full overflow-y-auto px-4 pb-4">
		<DebugSection>
			<pre>{{ data }}</pre>
		</DebugSection>
		<FormKit v-model="data" type="form" :actions="false">
			<div class="grid grid-cols-12 gap-x-2 gap-y-4">
				<FormKitSchema :schema="variableSchema" />
			</div>
		</FormKit>
	</div>
</template>

<script setup lang="ts">
	import { defineProps, ref } from 'vue';
	import { FormKitSchemaNode } from '@formkit/core';
	import { ActivityTemplateVariables, ActivityTemplateVariableType, ActivityTemplateVariableTypeLabel } from '@/types/activityTemplate';
	import { BuildElementBox } from '@modules/form/utils/options';
	import { CardOption } from '@/types/form';
	import DebugSection from '@components/DebugSection.vue';

	interface Props {
		variable: ActivityTemplateVariables;
	}

	const props = defineProps<Props>();

	const data = ref<ActivityTemplateVariables>(props.variable);

	const variableSchema: FormKitSchemaNode[] = [
		{
			$formkit: 'text',
			name: 'name',
			label: 'Name',
			validation: [['safeAlphaNumeric']],
			help: 'What is the name of the variable. (required) (unique)',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
		{
			$formkit: 'text',
			name: 'description',
			label: 'Description',
			help: 'What is the description for the variable. (optional)',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
		{
			$formkit: 'toggle',
			id: 'group',
			name: 'group',
			label: 'Is this variable a folder',
			value: false,
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
		{
			$formkit: 'toggle',
			id: 'list',
			name: 'list',
			if: '$get(group).value != true',
			label: 'Is this variable a list',
			value: false,
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
		{
			$formkit: 'cardGroup',
			outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true },
			validation: [['required']],
			id: 'type',
			name: 'type',
			if: '$get(group).value != true',
			label: 'Type',
			simple: true,
			cardOptions: {
				if: '$get(list).value',
				then: [
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.STRING],
						value: ActivityTemplateVariableType.STRING,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.NUMBER],
						value: ActivityTemplateVariableType.NUMBER,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.DATE],
						value: ActivityTemplateVariableType.DATE,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.DATETIME],
						value: ActivityTemplateVariableType.DATETIME,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.TIME],
						value: ActivityTemplateVariableType.TIME,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.BOOLEAN],
						value: ActivityTemplateVariableType.BOOLEAN,
					},
				] as CardOption[],
				else: [
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.STRING],
						value: ActivityTemplateVariableType.STRING,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.NUMBER],
						value: ActivityTemplateVariableType.NUMBER,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.DATE],
						value: ActivityTemplateVariableType.DATE,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.DATETIME],
						value: ActivityTemplateVariableType.DATETIME,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.TIME],
						value: ActivityTemplateVariableType.TIME,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.JSON],
						value: ActivityTemplateVariableType.JSON,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.BOOLEAN],
						value: ActivityTemplateVariableType.BOOLEAN,
					},
				] as CardOption[],
			},
		},
		{
			$formkit: 'toggle',
			id: 'read_only',
			name: 'read_only',
			label: 'Read only',
			if: '$get(group).value != true',
			help: 'Value can only be read and not updated',
			value: false,
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
		{
			$formkit: 'toggle',
			id: 'tracked',
			name: 'tracked',
			label: 'Track changes',
			if: '$get(group).value != true',
			help: 'Track each change made to this variable',
			value: true,
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
		{
			$formkit: 'toggle',
			id: 'hidden',
			name: 'hidden',
			label: 'Hidden',
			value: false,
			if: '$get(group).value != true',
			help: "Variable exists and can be changed but can't be seen'",
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
		{
			$el: 'div',
			if: '$get(group).value != true && $get(list).value != true',
			attrs: {
				class: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-12': true,
					'w-full': true,
				},
			},
			children: [
				{
					$formkit: 'text',
					id: 'initial_value',
					name: 'initial_value',
					label: 'Initial Value',
					if: '$get(type).value == "string"',
					outerClass: {
						'col-span-12': true,
						'sm:col-span-12': true,
						'lg:col-span-12': true,
						'xl:col-span-12': true,
					},
				},
				{
					$formkit: 'number',
					id: 'initial_value',
					name: 'initial_value',
					label: 'Initial Value',
					if: '$get(type).value == "number"',
					outerClass: {
						'col-span-12': true,
						'sm:col-span-12': true,
						'lg:col-span-12': true,
						'xl:col-span-12': true,
					},
				},
				{
					$formkit: 'toggle',
					id: 'initial_value',
					name: 'initial_value',
					label: 'Initial Value',
					if: '$get(type).value == "boolean"',
					outerClass: {
						'col-span-12': true,
						'sm:col-span-12': true,
						'lg:col-span-12': true,
						'xl:col-span-12': true,
					},
				},
				{
					$formkit: 'date',
					id: 'initial_value',
					name: 'initial_value',
					label: 'Initial Value',
					if: '$get(type).value == "date"',
					outerClass: {
						'col-span-12': true,
						'sm:col-span-12': true,
						'lg:col-span-12': true,
						'xl:col-span-12': true,
					},
				},
				{
					$formkit: 'datetime-local',
					id: 'initial_value',
					name: 'initial_value',
					label: 'Initial Value',
					if: '$get(type).value == "datetime"',
					outerClass: {
						'col-span-12': true,
						'sm:col-span-12': true,
						'lg:col-span-12': true,
						'xl:col-span-12': true,
					},
				},
				{
					$formkit: 'time',
					id: 'initial_value',
					name: 'initial_value',
					label: 'Initial Value',
					if: '$get(type).value == "time"',
					outerClass: {
						'col-span-12': true,
						'sm:col-span-12': true,
						'lg:col-span-12': true,
						'xl:col-span-12': true,
					},
				},
			],
		},
		{
			$el: 'div',
			if: '$get(group).value != true && $get(list).value == true && $get(type).value',
			attrs: {
				class: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-12': true,
					'w-full': true,
				},
			},
			children: [
				...BuildElementBox('Initial List options', [
					{
						$formkit: 'selectOptions',
						id: 'initial_value',
						name: 'initial_value',
						meta: {
							customListType: true,
						},
						inputType: {
							if: '$get(type).value == "string"',
							then: 'text',
							else: {
								if: '$get(type).value == "number"',
								then: 'number',
								else: {
									if: '$get(type).value == "date"',
									then: 'date',
									else: {
										if: '$get(type).value == "datetime"',
										then: 'datetime-local',
										else: {
											if: '$get(type).value == "time"',
											then: 'time',
											else: {
												if: '$get(type).value == "boolean"',
												then: 'toggle',
												else: 'text',
											},
										},
									},
								},
							},
						},
						outerClass: {
							'col-span-12': true,
							'sm:col-span-12': true,
							'lg:col-span-12': true,
							'xl:col-span-12': true,
						},
					},
				]),
			],
		},
	];
</script>

<style scoped></style>
